import { api } from '../utils/api';
/** 获取任务列表  */
export async function getTaskList(data) {
    return api('/web/task/task/list', data, 'POST', false, false);
}

// 任务详情
export async function getTaskDetail(data) {
    return api('/web/task/task/info', data, 'POST', false);
}

// 接受任务
export async function receiveTask(data) {
    return api('/web/task/task/join', data, 'POST', true);
}

// 删除任务
export async function deleteTask(data) {
    return api('/web/task/task/delete', data, 'POST', true, true, false);
}

// 用户已接收的任务列表
export async function getUserTaskList(data) {
    return api('/web/task/task/user', data, 'POST', true, true);
}

// 用户 任务统计
export async function getUserTaskCount(data) {
    return api('/web/task/task/group-count', data, 'POST', true, true, false);
}

// 用户  完成任务
export async function userCompleteTask(data) {
    return api('/web/task/task/complete', data, 'POST', true, true);
}

// 首页推荐位
// 获取 全部任务 用于 首页推荐位（取任务的第一条数据作为推荐位展示）
export async function getRecommendPosition(data) {
    return api('/web/task/task/list', data, 'POST', false, false, false);
}
